import Swiper from 'swiper';
import Alpine from 'alpinejs';

// Challenge Form object
// Contains info for segment and brandbot
// We need to use the brandbot form structure for in studio forms
// We need to use the segment form structure for at home form 
// With the brandbot form, there should be a dropdown for locations that switch
// out the ids
//


const studioList = [
  {
    name: "Algonquin",
    id: "algonquin",
    brandBotId: "ekkk",
    bbUUID: "2da2d088-e37a-4c31-9d00-e5e7c020c832"
  },
  {
    name: "Calgary",
    id: "calgary",
    brandBotId: "56525",
    bbUUID: "26aae75c-0fa8-4e8e-90a8-fb0992220e02"
  },
  {
    name: "Carlsbad",
    id: "carlsbad",
    brandBotId: "izhcmy",
    bbUUID: "e170f38b-269b-4f17-9bf8-c5ed87922667"
  },
  {
    name: "Chicago",
    id: "chicago",
    brandBotId: "qlsutc",
    bbUUID: "c88ec1df-2074-4c28-9504-bd14528e1bb3"
  },
  {
    name: "Columbia",
    id: "columbia",
    brandBotId: "o0onum",
    bbUUID: "ff5acfd0-67e4-4d29-bdb8-59dec221f5ad"
  },
  {
    name: "Fort Worth",
    id: "fort-worth",
    brandBotId: "pk4aj",
    bbUUID: "a59bb06d-2128-491b-9371-c39a8cb1f2be"
  },
  {
    name: "Los Angeles",
    id: "los-angeles",
    brandBotId: "gz6oke",
    bbUUID: "e1e5b85a-67e3-4180-be0d-4f2c89d2d31b"
  },
  {
    name: "Nashville",
    id: "nashville",
    brandBotId: "29syel",
    bbUUID: "783b86be-f4c5-4a7f-9864-7cfc78948c27"
  },
  {
    name: "New York City",
    id: "new-york-city",
    brandBotId: "jq23v5",
    bbUUID: "04b59ebe-8c32-4248-b64a-821e01e948cc"
  },
  {
    name: "San Diego",
    id: "san-diego",
    brandBotId: "lfo5pl",
    bbUUID: "f8fb2ed0-3170-4af3-a119-80d5ebca1144"
  },
  {
    name: "Upper Saddle River",
    id: "upper-saddle-river",
    brandBotId: "24s0x",
    bbUUID: "7c400a0a-ddc0-497f-a68c-6583b4d18e66"
  },
  {
    name: "Victoria",
    id: "victoria",
    brandBotId: "2zdbva",
    bbUUID: "a7e8030c-2fc7-47fc-801c-2935dfb9ea8e"
  },
]

export function challengeForm(sectionId) {
  console.log(sectionId)
  return {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    termsAccepted: false,
    marketingConsent: false,
    // Studio specific info
    selectedStudio: "",
    studioList: studioList,
    activeBrandBotId: "",
    activeBBUUID: "",
    unlimitedMember: "",
    selectedCountry: "",
    countryList: ["United States", "Canada", "United Kingdom", "Germany", "Australia", "Other"],
    activeTab: 0, // This sets the active tab for the forms, can be modified using ?form= in the url
    swiper: null, // This will hold the Swiper instance
    tabs: [], // Array to store tab titles
    formState: 'initial', // can be initial, loading, success, error
    errorMessage: null,

    init() {
      // this.$nextTick(() => {
      //   this.initSwiper();
      //   this.swiper.update()
      // });
      this.tabs = Array.from(document.querySelectorAll(`.challenge-sign-up_carousel-pagination-item-${sectionId}`))
        .map(item => item.dataset.title);
      this.initSwiper()
    },

    initSwiper() {
      this.swiper = new Swiper(`.challenge-sign-up_carousel-${sectionId}`, {
        centerInsufficientSlides: true,
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,
        autoHeight: true,
        preventClicks: false,
        preventClicksPropagation: false,
      });

      this.swiper.on('slideChange', (e) => {
        this.marketingConsent = false
        this.termsAccepted = false
        this.activeTab = e.activeIndex
        this.formState = 'initial'
        this.errorMessage = null
        Alpine.nextTick(() => {
          this.swiper.updateAutoHeight()
        })
      })

      const urlParams = new URLSearchParams(window.location.search);
      const formParam = urlParams.get('form');
      if (formParam) {
        if (formParam === 'studios') {
          this.changeTab(1)
        }
      }
    },
    changeCountry(country) {
      this.selectedCountry = country
    },
    changeTab(index) {
      console.log('change tab', index)
      this.termsAccepted = false
      this.marketingConsent = false
      if (index !== -1) {
        this.activeTab = index;
        this.swiper.slideTo(index);
      } else {
        console.error("Tab title not found:", index);
      }
    },
    async submitForm(brandBotForm = false) {
      this.formState = 'initial'
      this.errorMessage = ""
      Alpine.store('user').email = this.email.toLowerCase()

      console.log("submit form", brandBotForm)
      if (!this.termsAccepted) {
        console.log("terms not accepted")
        this.errorMessage = "Please accept the terms and conditions"
        this.formState = 'error'
        return
      }

      if (brandBotForm) {
        console.log('brandbot form')
        this.formState = 'loading'
        if (!this.selectedStudio) {
          this.formState = 'error'
          this.errorMessage = "Please select a studio"
          return
        }
        if (this.phone.length < 10) {
          this.formError('Please enter a valid phone number.')
          return
        }
        const bbFormRequest = await sendBrandBotForm({
          bb_first_name: this.firstName,
          bb_last_name: this.lastName,
          bb_email: this.email,
          bb_phone: this.phone,
          bb_uuid: this.activeBBUUID,
          terms: "on",
          activeBrandBotId: this.activeBrandBotId,
          foundingMember: this.unlimitedMember,
        })
        console.log('bbFormRequest', bbFormRequest)
        if (!bbFormRequest) {
          this.formState = 'error'
          this.errorMessage = "Error submitting form"
          return
        }
        Alpine.store('user').sendSegmentEvent(
          {
            email: this.email,
            first_name: this.firstName,
            home_studio_location: this.selectedStudio,
            last_name: this.lastName,
            phone: this.phone
          },
          null
        )
        this.formState = 'success'
        Alpine.nextTick(() => {
          this.swiper.updateAutoHeight()
        })
      Alpine.nextTick(() => {
        this.scrollToSuccessBanner('success-message')
      })
        return
      }
      Alpine.store('user').sendSegmentEvent(
        {
          email: this.email,
          email_marketing_opted_in: this.marketingConsent ? 'opted_in' : 'subscribed',
          spring_challenge_2024_country: this.selectedCountry
        },
        {
          name: "Marketing Opted In",
          email: this.email,
          is_email_opt_in: this.marketingConsent ? true : false,
          source: window.location.host,
          source_detail: 'spring_challenge_signup_2024'
        })

      console.log('challenge form submitted', this);
      this.formState = 'success'
      Alpine.nextTick(() => {
        this.swiper.updateAutoHeight()
      })
      Alpine.nextTick(() => {
        this.scrollToSuccessBanner('success-message')
      })
      return
    },
    changeStudio(studioId) {
      console.log(studioId)
      const studioInfo = this.studioList.find(s => s.id === studioId)
      if (!studioInfo || !studioId) {
        this.activeBBUUID = ""
        this.selectedStudio = ""
        this.activeBrandBotId = ""
        return
      }
      this.activeBBUUID = studioInfo.bbUUID
      this.selectedStudio = studioId
      this.activeBrandBotId = studioInfo.brandBotId

    },
    changeUnlimitedMember(value) {
      this.unlimitedMember = value
    },
    scrollToSuccessBanner(id) {
      if (!id) {
        return
      }
      const successBanner = document.getElementById(id);
      console.log('success banner', successBanner)

      if (successBanner) {
        successBanner.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    async formError(message) {
      this.formState = 'error'
      if (message) {
        this.errorMessage = message
      } else {
        this.errorMessage = "<div>Something went wrong. Please try again in a few minutes. If you continue to see this error, reach out to <a href='mailto:help@pvolve.com'><strong>help@pvolve.com</strong></a>.</div>"
      }

      Alpine.nextTick(() => this.swiper.updateAutoHeight())
    }
  }
}

export const sendBrandBotForm = async (data) => {
  const formData = new FormData();

  formData.append('bb_first_name', data.bb_first_name);
  formData.append('bb_last_name', data.bb_last_name);
  formData.append('bb_email', data.bb_email);
  formData.append('bb_phone', data.bb_phone);
  formData.append('bb_uuid', data.bb_uuid);
  formData.append('terms', 'on');
  formData.append('bb_custom_are-you-a-founding-member', data.foundingMember);
  const urlEncodedData = new URLSearchParams();
  formData.forEach((value, key) => {
    urlEncodedData.append(key, value);
  });

  const req = await fetch('https://microservices.brndbot.net/forms/answers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: urlEncodedData
  })
  const res = await req.json()
  if (res.message?.includes("success")) {
    return true
  } else {
    return false
  }
}
